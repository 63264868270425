<template>
  <div id="app">
    <div>
      <App @open-click="openDrawer" ref="HandIndex" />
    </div>

    <div>
      <el-drawer v-model="open" direction="rtl" :with-header="false" size="40%" :close-delay="100">
        <div
          style="display: flex;flex-direction: column;justify-content: left;align-items: start;width: 100%;padding-top: 5px;">
          <div
            :style="{ backgroundColor: green == 0 ? '#01BB74' : '#fff', color: green == 0 ? '#fff' : '#333333', fontWeight: green == 0 ? 'bold' : 'normal' }"
            style="height: 40px;width: 100%;display: flex;flex-direction: row;justify-content: center;align-items: center;border-radius: 6px;margin-bottom: 5px;"
            @click="DrawerClick(0)">
            首页
          </div>
          <div
            :style="{ backgroundColor: green == 1 ? '#01BB74' : '#fff', color: green == 1 ? '#fff' : '#333333', fontWeight: green == 1 ? 'bold' : 'normal' }"
            style="height: 40px;width: 100%;display: flex;flex-direction: row;justify-content: center;align-items: center;border-radius: 6px;margin-bottom: 5px;"
            @click="DrawerClick(1)">
            APP下载
          </div>
          <div
            :style="{ backgroundColor: green == 2 ? '#01BB74' : '#fff', color: green == 2 ? '#fff' : '#333333', fontWeight: green == 2 ? 'bold' : 'normal' }"
            style="height: 40px;width: 100%;display: flex;flex-direction: row;justify-content: center;align-items: center;border-radius: 6px;margin-bottom: 5px;"
            @click="DrawerClick(2)">
            服务信息
          </div>
        </div>
      </el-drawer>

      <div class="imgList" ref="APPA" v-if="green == 0">
        <ImgView :url="appImg[0]" ImgHeight="300px">
          <template v-slot:body>
            <div class="imgList001" style="">
              <div class="imgList001_01">
                <div class="imgList001_01_hander">简单易用，快捷上手</div>
                <div class="imgList001_01_title">一款便捷、高效、准确的电子单 </div>
                <div class="imgList001_01_title">据服务平台 </div>
                <div class="imgList001_01_btn"></div>
              </div>
              <div style="position: absolute;right: 10px;bottom: 20px;">
                <img src="../../public/app/order.webp" style="width: 140px;height: 140px;">
              </div>
            </div>
          </template>
        </ImgView>
        <EmptyView :height="480" color="#FFFFFF">
          <template v-slot:body>
            <div class="imgList002" style="">
              <div class="imgList002_title">
                <div class="imgList002_title_hand">
                  <img src="../../public/app/hj.webp" style="width: 30px;height: 30px;margin-right: 5px;">
                  <text class="imgList002_title_hander">提高效率，降低成本</text>
                </div>
                <div class="imgList002_title_t">
                  <div class="imgList002_title_t_d" style=""></div>
                  手机一键开单，简化开单流程
                </div>
                <div class="imgList002_title_t">
                  <div class="imgList002_title_t_d" style=""></div>
                  关键词输入检索，快速查询订单
                </div>
                <div class="imgList002_title_t">
                  <div class="imgList002_title_t_d" style=""></div>
                  订单数据可视化呈现
                </div>
              </div>

              <div style="position: absolute;bottom: 30px;">
                <img src="../../public/app/phone.webp" style="width: auto;height: 240px;">
              </div>
            </div>
          </template>
        </EmptyView>
        <ImgView :url="appImg[2]" ImgHeight="480px">
          <template v-slot:body>
            <div class="imgList002" style="">
              <div class="imgList002_title">
                <div class="imgList002_title_hand">
                  <img src="../../public/app/fun.webp" style="width: 30px;height: 30px;margin-right: 5px;">
                  <text class="imgList002_title_hander">收发两端，快速切换 </text>
                </div>
                <div class="imgList002_title_t">
                  <div class="imgList002_title_t_d" style=""></div>
                  一个账号，两种角色
                </div>
                <div class="imgList002_title_t">
                  <div class="imgList002_title_t_d" style=""></div>
                  通过按钮切换收发端口
                </div>
                <div class="imgList002_title_t">
                  <div class="imgList002_title_t_d" style=""></div>
                  满足用户销售和采买货品双向需求
                </div>
              </div>

              <div style="position: absolute;bottom: 30px;">
                <img src="../../public/app/phone2.webp" style="width: auto;height: 240px;">
              </div>
            </div>
          </template>
        </ImgView>
        <EmptyView :height="500" color="#FFFFFF">
          <template v-slot:body>
            <div class="imgList003">
              <div class="imgList003_hander">
                特色功能
              </div>
              <div class="imgList003_icon">
                <div class="imgList003_icon_img">
                  <div class="imgList003_icon_img_r">
                    <img src="../../public/app/ic01.webp" style="width: 55px;height: 55px;">
                  </div>
                  <div class="imgList003_icon_img_t">一键开单 </div>
                  <div class="imgList003_icon_img_s">向客户线上开送货单 </div>
                </div>
                <div class="imgList003_icon_img">
                  <div class="imgList003_icon_img_r">
                    <img src="../../public/app/ic02.webp" style="width: 55px;height: 55px;">
                  </div>
                  <div class="imgList003_icon_img_t">订单统计 </div>
                  <div class="imgList003_icon_img_s">分类统计订单数据 </div>
                </div>
              </div>
              <div class="imgList003_icon">
                <div class="imgList003_icon_img">
                  <div class="imgList003_icon_img_r">
                    <img src="../../public/app/ic03.webp" style="width: 55px;height: 55px;">
                  </div>
                  <div class="imgList003_icon_img_t">人员管理 </div>
                  <div class="imgList003_icon_img_s">分管账号进行工作管控 </div>
                </div>
                <div class="imgList003_icon_img">
                  <div class="imgList003_icon_img_r">
                    <img src="../../public/app/ic04.webp" style="width: 55px;height: 55px;">
                  </div>
                  <div class="imgList003_icon_img_t">电子票据 </div>
                  <div class="imgList003_icon_img_s">在线完成订单签收 </div>
                </div>
              </div>
            </div>
          </template>
        </EmptyView>
        <!-- <ImgView :url="appImg[3]" ImgHeight="550px">

        </ImgView> -->
        <ImgView :url="appImg[4]" ImgHeight="350px">
          <template v-slot:body>
            <div class="imgList004">
              <div class="imgList004_hander">易单据</div>
              <div class="imgList004_text">创造更多价值，让生意更容易</div>
            </div>
          </template>
        </ImgView>
        <AppEnd @move-click="moveClick"></AppEnd>
      </div>

      <div class="imgList" ref="APPB" v-if="green == 1">
        <ImgView url="../app/appDown001.webp" ImgHeight="280px">
          <template v-slot:body>
            <div class="appAbout001">
              <div class="appAbout001_all">
                <div class="appAbout001_hander">一键下载，快速安装</div>
                <div class="appAbout001_text">点击下载app，开启电子单据之旅</div>
              </div>

            </div>
          </template>
        </ImgView>
        <ImgView url="../app/appDown003.webp" ImgHeight="450px"></ImgView>
        <ImgView url="../app/appDown002.webp" ImgHeight="450px"></ImgView>
        <EmptyView :height="460" color="#FFFFFF">
          <template v-slot:body>
            <div class="AppAboutDown">
              <div class="AppAboutTitle">快速下载</div>
              <div class="AppAboutDownBody">

                <div class="andToIos">
                  <div class="card anzhuo" @click="Down(0)">
                    <el-image style="width: 50px; height: 50px;" src="../web/az.webp" />
                    <div>安卓下载 </div>
                    <el-image style="width: 15px; height: 15px;position: absolute;right: 15px;top: 15px;"
                      src="../web/down.webp" />
                  </div>
                  <div class="card ios" @click="Down(1)">
                    <el-image style="width: 50px; height: 50px;" src="../web/ios.webp" />
                    <div>安卓下载 </div>
                    <el-image style="width: 15px; height: 15px;position: absolute;right: 15px;top: 15px;"
                      src="../web/down.webp" />
                  </div>
                </div>
                <div class="andToIosSM">
                  <el-image style="width: 100px; height: 100px;" src="../web/BDs2vSLpNK.jpg" />
                  <div style="color: #333333;margin-top: 10px;font-size: 14px;">手机扫码下载移动端APP </div>
                  <div style="color:#999999;margin-top: 5px;font-size: 14px;">支持安卓和iOS设备 </div>
                </div>

              </div>
            </div>
          </template>
        </EmptyView>
        <AppEnd @move-click="moveClick"></AppEnd>
      </div>

      <div class="imgList" ref="APPC" v-if="green == 2">
        <ImgView url="../app/appAbout111.webp" ImgHeight="300px">
          <template v-slot:body>
            <div class="appAbout002">
              <div class="appAbout002_all">
                <div class="appAbout002_hander">上海安航云数字科技</div>
                <div class="appAbout002_text">以用户为中心，从实际出发</div>
              </div>

            </div>
          </template>
        </ImgView>
        <EmptyView :height="520" color="#FFFFFF">
          <template v-slot:body>
            <div class="AppAbout01">
              <div class="AppAboutTitle">公司简介</div>

              <div class="AppAbout01Body">
                <div class="AppAboutText">
                  <br>
                  <span>
                    上海安航云数字科技有限公司，成立于2024年，是一家专注于为用户提供高效、准确、便捷的电子单据服务的企业。
                  </span>
                  <br>
                  <br>
                  <span>
                    我们的核心产品——“易单据”，以“每一笔订单都尽在掌握”为使命，集订单创建、签收、查询、统计于一体，一机在手，轻松管理，实现无纸化、自动化、智能化的单据处理模式。
                  </span>
                </div>
                <br>
                <br>
                <el-image style="width: 320px; height: 210px;border-radius: 6px;" src="../app/app002about.webp" />

              </div>
            </div>
          </template>
        </EmptyView>
        <EmptyView :height="670" color="#F7FEFD">
          <template v-slot:body>
            <div class="AppAbout02">
              <div class="AppAboutTitle">价值体系 </div>
              <div class="AppAbout01Body">
                <div class="AppAboutText">
                  <br>
                  <span>
                    安航云数字科技坚持以用户为中心的设计理念，从实际出发，深入了解不同群体、行业的开单需求，模拟最真实的线下开单方式，确保用户在实际使用过程中能够得到更流畅、更完整的开单体验。
                  </span>
                  <br>
                  <br>
                  <span>
                    未来，安航云数字科技也将继续深耕电子单据领域，优化产品功能，不断探索开单新形式，为用户提供更加全面、优质的单据处理服务。
                  </span>
                </div>
                <br>
                <br>
                <div class="AppAbout02Img">
                  <div class="AppAbout02ImgCard">
                    <el-image style="width: 100%; height: 112px;border-radius: 6px 6px 0 0;"
                      src="../app/appAbout00201.webp" />
                    <div class="AppAbout02ImgCardHand">
                      使命
                    </div>
                    <div class="AppAbout02ImgCardText">
                      每一笔订单都尽在掌握
                    </div>
                  </div>
                  <div class="AppAbout02ImgCard">
                    <el-image style="width: 100%; height: 112px;border-radius: 6px 6px 0 0;"
                      src="../app/appAbout00202.webp" />
                    <div class="AppAbout02ImgCardHand">
                      价值观
                    </div>
                    <div class="AppAbout02ImgCardText">
                      简单、创新、务实
                    </div>
                  </div>
                </div>
                <div class="AppAbout02Img">
                  <div class="AppAbout02ImgCard">
                    <el-image style="width: 100%; height: 112px;border-radius: 6px 6px 0 0;"
                      src="../app/appAbout00203.webp" />
                    <div class="AppAbout02ImgCardHand">
                      愿景
                    </div>
                    <div class="AppAbout02ImgCardText">
                      开单就用易单据
                    </div>
                  </div>
                  <div class="AppAbout02ImgCard" style="opacity: 0;">
                    <el-image style="width: 100%; height: 112px;border-radius: 6px 6px 0 0;"
                      src="../app/appAbout00202.webp" />
                    <div class="AppAbout02ImgCardHand">
                      使命
                    </div>
                    <div class="AppAbout02ImgCardText">
                      每一笔订单都尽在掌握
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </template>
        </EmptyView>
        <EmptyView :height="670" color="#FFFFFF">
          <template v-slot:body>
            <div class="AppAbout03">
              <div class="AppAboutTitle">服务热线 </div>
              <div class="AppAbout03Body">
                <div class="AppAbout03HnadTitleO">
                  <div class="AppAbout03HnadTitle">
                    上海安航云数字科技有限公司
                  </div>
                  <div class="AppAbout03HnadTitleText">
                    地址：上海浦东秀沿路3188弄创研智造C3幢103室
                  </div>
                </div>
                <div class="AppAbout03HnadTitleO">
                  <div class="AppAbout03HnadTitle">
                    网络服务
                  </div>
                  <div class="AppAbout03HnadTitleText">
                    邮箱：upbill.cn@aliyun.com
                  </div>
                  <div class="AppAbout03HnadTitleText">
                    网址：www.upbill.cn
                  </div>
                </div>
                <div class="AppAbout03HnadTitleO">
                  <div class="AppAbout03HnadTitle">
                    客户服务
                  </div>
                  <div class="AppAbout03HnadTitleText">
                    电话：18117500187 / 13966760201
                  </div>
                </div>
                <el-image style="width: 320px; height: 313px;border-radius: 14px 14px 14px 14px;"
                  src="../app/appAbout00204.webp" />
                <!-- appAbout00204.webp -->
              </div>
            </div>

          </template>
        </EmptyView>
        <AppEnd @move-click="moveClick"></AppEnd>
      </div>

    </div>
  </div>
</template>
<script>
import App from '../modularization/AppHand.vue'
import ImgView from '../modularization/ImgView.vue'
import AppEnd from '../modularization/APP/appEnd.vue'
import EmptyView from '../modularization/EmptyView.vue'

export default {
  components: {
    App,
    ImgView,
    AppEnd,
    EmptyView
  },
  data() {
    return {
      open: false,
      TabWidth: "1800px",
      green: 0,
      show: false,
      appImg: [
        "../app/app001.webp",
        "../app/app002.webp",
        "../app/app003.webp",
        "../app/app004.webp",
        "../app/app005.webp",
        "../app/app006.webp"
      ],
      appDown: [],
      appAbout: [

      ],
      root: true
    }
  },
  created() {


  },
  mounted() {

  },
  methods: {
    moveClick(index, y) {
      console.log("触发", index, y);
      this.green = index
      this.MOVEtIME(y, index);

    },
    MOVEtIME(y, index) {
      var that = this;
      setTimeout(function () {
        switch (index) {
          case 0:
            that.$refs.APPA.scrollTop = that.$refs.APPA.scrollHeight * y;
            break;
          case 1:
            that.$refs.APPB.scrollTop = that.$refs.APPB.scrollHeight * y;
            break;
          case 2:
            that.$refs.APPC.scrollTop = that.$refs.APPC.scrollHeight * y;
            break;
          default:
            break;
        }

      }, 100)
    },
    DrawerClick(click) {
      this.open = false;
      this.green = click
      console.log(click);
    },
    openDrawer(open) {
      this.open = open
    },
    Down(index) {
      if (index == 0) {
        window.location.href = "https://res-oss.elist.com.cn/app/android/app.apk"
      } else {
        window.location.href = "https://res-oss.elist.com.cn/app/android/app.apk"
      }
    }
  }
}
</script>
<style scoped lang="scss">
.app {
  width: 100%;
  height: 100%;
  background-color: aqua;
}

.imgList {
  overflow-y: scroll;
  height: 100vh;
  width: 100%;
}

.imgList001 {
  width: 100%;
  height: 100%;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;

  .imgList001_01 {

    position: absolute;
    left: 9%;

    margin-top: 20px;

    .imgList001_01_hander {
      font-family: Microsoft YaHei;
      font-size: 24px;
      font-weight: bold;
      line-height: normal;
      letter-spacing: 0em;
      color: #333333;
      margin-bottom: 15px;
    }

    .imgList001_01_title {
      font-family: Microsoft YaHei;
      font-size: 13px;
      font-weight: normal;
      line-height: 168.33%;
      letter-spacing: 0.01em;

      font-variation-settings: "opsz" auto;
      color: #333333;
    }

  }


}


.imgList002 {
  width: 100%;
  height: 100%;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;

  .imgList002_title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 280px;

    .imgList002_title_hand {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;


      .imgList002_title_hander {

        font-family: Microsoft YaHei;
        font-size: 24px;
        font-weight: bold;
        line-height: normal;
        letter-spacing: 0em;

        font-variation-settings: "opsz" auto;
        color: #333333;



      }


    }

    .imgList002_title_t {

      display: flex;
      flex-direction: row;
      align-items: center;

      font-family: Microsoft YaHei;
      font-size: 13px;
      font-weight: normal;
      line-height: normal;
      letter-spacing: 0.01em;

      // background-color: aqua;

      width: 210px;

      margin-left: 40px;

      text-align: left;

      padding-top: 6px;

      font-variation-settings: "opsz" auto;
      color: #666666;

      .imgList002_title_t_d {
        border-radius: 100px;
        background-color: #01BB74;
        width: 5px;
        height: 5px;
        margin-right: 7px;

      }
    }


  }
}

.imgList003 {

  width: 100%;
  height: 100%;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
  position: relative;

  .imgList003_hander {
    font-family: Microsoft YaHei;
    font-size: 18px;
    font-weight: bold;
    line-height: normal;
    letter-spacing: 0em;

    font-variation-settings: "opsz" auto;
    color: #333333;

    padding-top: 40px;
  }

  .imgList003_icon {
    width: 100%;
    height: 45%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;

    margin-top: 10px;

    .imgList003_icon_img {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      width: 50%;
      // background-color: #01BB74;

      height: 100%;

      .imgList003_icon_img_r {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        width: 105px;
        height: 105px;
        background: #FFFFFF;

        box-shadow: 0.48px 0.96px 3.84px 0px rgba(102, 115, 110, 0.2);

        border-radius: 100%;

      }

      .imgList003_icon_img_t {
        font-family: Source Han Sans;
        font-size: 17px;
        font-weight: 600;
        line-height: normal;
        text-align: center;
        letter-spacing: 0.01em;

        font-variation-settings: "opsz" auto;
        font-feature-settings: "kern" on;
        color: #333333;

        margin-top: 15px;
      }

      .imgList003_icon_img_s {
        font-family: Microsoft YaHei;
        font-size: 12px;
        font-weight: normal;
        line-height: normal;
        text-align: center;
        letter-spacing: 0.01em;

        font-variation-settings: "opsz" auto;
        color: #666666;

        margin-top: 5px;
      }
    }
  }

}


.imgList004 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  .imgList004_hander {
    font-family: Microsoft YaHei;
    font-size: 30px;
    font-weight: bold;
    line-height: normal;
    text-align: center;
    letter-spacing: 0.02em;

    font-variation-settings: "opsz" auto;
    color: #333333;
  }

  .imgList004_text {
    font-family: Source Han Sans;
    font-size: 18px;
    font-weight: normal;
    line-height: normal;
    text-align: center;
    letter-spacing: 0.02em;

    font-variation-settings: "opsz" auto;
    font-feature-settings: "kern" on;
    color: #333333;

    margin-top: 20px;
  }
}


.AppAboutTitle {
  width: 100%;
  padding-top: 38px;
  text-align: center;
  color: #333333;
  font-family: Microsoft YaHei;
  font-size: 18px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: 0em;
  font-variation-settings: "opsz" auto;
}


.AppAboutText {
  font-family: Source Han Sans;
  font-size: 12px;
  font-weight: normal;
  line-height: 158.43%;
  letter-spacing: 0.03em;

  font-variation-settings: "opsz" auto;
  font-feature-settings: "kern" on;
  color: #666666;
}


.AppAboutDown {
  width: 100%;
  height: 100%;

  position: relative;



  .AppAboutDownBody {
    position: absolute;
    width: 100%;
    height: 80%;

    bottom: 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    // background-color: #666666;

    .andToIos {
      height: 40%;
      width: 100%;
      // background-color: aqua;

      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      .card {
        box-shadow: -0.73px 0px 1.1px 0px rgba(143, 143, 143, 0.1), 2.2px 1.83px 1.1px 0px rgba(143, 143, 143, 0.2);
        border-radius: 9.52px;
        font-size: 10px;
      }

      .anzhuo {
        margin-right: 10px;
        width: 152.66px;
        height: 106.04px;

        position: relative;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      .ios {
        margin-left: 10px;
        width: 152.66px;
        height: 106.04px;
        position: relative;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

    }

    .andToIosSM {
      width: 85%;
      height: 50%;
      border-radius: 9.52px;
      box-shadow: -0.73px 0px 1.1px 0px rgba(143, 143, 143, 0.1), 2.2px 1.83px 1.1px 0px rgba(143, 143, 143, 0.2);

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}


.AppAbout01 {
  width: 100%;
  height: 100%;
  // background-color: aquamarine;
  position: relative;

  .AppAbout01Body {
    position: absolute;
    bottom: 0;
    height: 85%;
    // background-color: #333333;

    display: flex;
    flex-direction: column;
    align-items: center;



    .AppAboutText {
      width: 80%;

    }
  }

}


.AppAbout02 {
  width: 100%;
  height: 100%;
  // background-color: aquamarine;
  position: relative;

  .AppAbout01Body {
    position: absolute;
    bottom: 0;
    height: 90%;
    // background-color: #333333;

    display: flex;
    flex-direction: column;
    align-items: center;

    .AppAbout02Img {
      // background-color: #333333;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      height: 50%;

      .AppAbout02ImgCard {
        border-radius: 8px 8px 0 0;
        background-color: white;
        height: 85%;
        width: 162px;
        margin-left: 10px;
        margin-right: 10px;

        display: flex;
        flex-direction: column;

        .AppAbout02ImgCardHand {
          font-family: Source Han Sans;
          font-size: 11.9px;
          font-weight: 900;
          line-height: 158.43%;
          letter-spacing: 0.03em;

          font-variation-settings: "opsz" auto;
          font-feature-settings: "kern" on;
          color: #333333;

          margin-left: 5%;
          margin-top: 5%;
        }

        .AppAbout02ImgCardText {
          font-family: Source Han Sans;
          font-size: 11px;
          font-weight: normal;
          line-height: 158.43%;
          letter-spacing: 0.03em;

          font-variation-settings: "opsz" auto;
          font-feature-settings: "kern" on;
          color: #666666;

          margin-left: 5%;
          margin-top: 2%;
        }

      }
    }

    .AppAboutText {
      width: 80%;

    }
  }
}


.AppAbout03 {
  width: 100%;
  height: 100%;
  // background-color: aquamarine;
  position: relative;

  .AppAbout03Body {
    position: absolute;
    bottom: 0;
    height: 86%;
    width: 100%;
    // background-color: #333333;

    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;

    .AppAbout03HnadTitleO {
      width: 100%;
      display: flex;
      flex-direction: column;

      margin-bottom: 20px;

      padding-left: 20%;

      .AppAbout03HnadTitle {
        font-family: Source Han Sans;
        font-size: 16px;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0em;

        font-variation-settings: "opsz" auto;
        font-feature-settings: "kern" on;
        color: #333333;

        margin-bottom: 5px;
      }

      .AppAbout03HnadTitleText {
        font-family: Source Han Sans;
        font-size: 14px;
        font-weight: normal;
        line-height: 161.71%;
        letter-spacing: 0em;

        font-variation-settings: "opsz" auto;
        font-feature-settings: "kern" on;
        color: #666666;
      }
    }


  }

}




.appAbout001 {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  // background-color: #333333;

  .appAbout001_all {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    margin-top: 20px;

    .appAbout001_hander {
      font-family: Microsoft YaHei;
      font-size: 24px;
      font-weight: bold;
      line-height: normal;
      letter-spacing: 0em;
      color: #333333;
      margin-bottom: 15px;

      // background-color: #01BB74;

      width: 330px;

      text-align: right;
    }

    .appAbout001_text {

      font-family: Microsoft YaHei;
      font-size: 13px;
      font-weight: normal;
      line-height: 168.33%;
      letter-spacing: 0.01em;

      font-variation-settings: "opsz" auto;
      color: #333333;
      width: 330px;

      text-align: right;
    }
  }
}

.appAbout002 {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  // background-color: #333333;

  .appAbout002_all {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    margin-top: 20px;

    .appAbout002_hander {
      font-family: Microsoft YaHei;
      font-size: 24px;
      font-weight: bold;
      line-height: normal;
      letter-spacing: 0em;
      color: #333333;
      margin-bottom: 15px;

      // background-color: #01BB74;

      width: 330px;

      text-align: right;
    }

    .appAbout002_text {

      font-family: Microsoft YaHei;
      font-size: 13px;
      font-weight: normal;
      line-height: 168.33%;
      letter-spacing: 0.01em;

      font-variation-settings: "opsz" auto;
      color: #333333;
      width: 330px;

      text-align: right;
    }
  }
}
</style>