<template>
  <div class="IndexBody" ref="scrollContainer">
    <ImgView :url="IndexUrl[0]" :ImgWidth="ImgWidth" ImgHeight="800px"></ImgView>
    <ImgView :url="IndexUrl[1]" :ImgWidth="ImgWidth" ImgHeight="800px">
      <template v-slot:body>
        <div class="cardAbout">
          <div class="left">
            <el-image style="width: 500px; height: 300px;border-radius: 15px;" :src="aboutImg[0]" />
          </div>
          <div class="right">
            <div class="text">
              <span class="p1"> 上海安航云数字科技有限公司，成立于2024年，是一家专注于为用户提供高效、准确、便捷的电子单据服务的企业。</span>
              <span class="p2"> 我们的核心产品——“易单据”，以“每一笔订单都尽在掌握”为使命，集订单创建、签收、查询、统计于一体，一机在手，轻松管理，实现无纸化、自动化、智能化的单据处理模式。</span>
            </div>
          </div>
        </div>
      </template>
    </ImgView>
    <ImgView :url="IndexUrl[2]" :ImgWidth="ImgWidth" ImgHeight="800px">
      <template v-slot:body>
        <div class="cardAboutJZTX">
          <div class="jztxCard">
            <div class="jztxImgCard">
              <el-image style="width: 100%; height: 300px;border-radius: 15px 15px 0 0;" :src="aboutImg[1]" />
              <span class="title">
                使命
              </span>
              <span class="t1">
                每一笔订单都尽在掌握
              </span>
            </div>
            <div class="jztxImgCard">
              <el-image style="width: 100%; height: 300px;border-radius: 15px 15px 0 0;" :src="aboutImg[2]" />
              <span class="title">
                价值观
              </span>
              <span class="t1">
                简单、创新、务实
              </span>
            </div>
            <div class="jztxImgCard">
              <el-image style="width: 100%; height: 300px;border-radius: 15px 15px 0 0;" :src="aboutImg[3]" />
              <span class="title">
                愿景
              </span>
              <span class="t1">
                开单就用易单据
              </span>
            </div>
          </div>
        </div>
      </template>
    </ImgView>
    <ImgView :url="IndexUrl[3]" :ImgWidth="ImgWidth" ImgHeight="1400px">
      <template v-slot:body>
        <div class="cardAboutFwxx">
          <div class="handTitle">
            <span class="ht1">
              安航云数字科技坚持以用户为中心的设计理念，从实际出发，深入了解不同群体、行业的开单需求，模拟最真实的线下开单方式，确保用户在实际使用过程中能够得到更流畅、更完整的开单体验。
            </span>
            <span class="ht2">
              未来，安航云数字科技也将继续深耕电子单据领域，优化产品功能，不断探索开单新形式，为用户提供更加全面、优质的单据处理服务。
            </span>
          </div>
          <div class="bodyCard">
            <div class="leftXxfw">
              <el-image style="width: 600px; height: 600px;border-radius: 15px;" :src="aboutImg[4]" />
            </div>
            <div class="rightXxfw">
              <div class="rightXxfwTitle">

                <div>
                  <span class="titleR">上海安航云数字科技有限公司</span>
                  <span class="textr">地址：上海浦东秀沿路3188弄创研智造C3幢103室</span>
                </div>

                <div>
                  <span class="titleR">网络服务</span>
                  <span class="textr">邮箱：upbill.cn@aliyun.com</span>
                  <span class="textr">网址：www.upbill.cn</span>
                </div>

                <div>
                  <span class="titleR">客户服务</span>
                  <span class="textr">电话：18117500187 / 13966760201</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </ImgView>
    <EndView :url="IndexUrl[4]" :ImgWidth="ImgWidth" @move-click="move"></EndView>
  </div>
</template>

<script>
import ImgView from '../modularization/ImgView.vue'
import EndView from '../modularization/EndView.vue'
import { useRouter } from 'vue-router'

export default {
  props: ['ImgWidth'],
  components: {
    ImgView,
    EndView
  },
  name: 'appDown',
  data() {
    return {
      TabWidth: "1800px",
      IndexUrl: [
        "../web/about001.webp",
        "../web/about002.webp",
        "../web/about003.webp",
        "../web/about004.webp",
        "../web/09.png",
      ],
      aboutImg: ["../web/about/about00201.webp", "../web/about/about00301.webp", "../web/about/about00302.webp", "../web/about/about00303.webp", "../web/about/about00401.webp"],
      router: null,
    }
  },
  mounted() {
    const router = useRouter()
    this.router = router;
  },
  methods: {
    MOVEScroll(y) {
      var that = this;
      setTimeout(function () {
        that.$refs.scrollContainer.scrollTop = that.$refs.scrollContainer.scrollHeight * y;
      }, 100)
    },
    move(index, y) {
      switch (index) {
        case 0:
          this.$emit('tab-click', index, y);
          break;
        case 1:
          this.$emit('tab-click', index, y);
          break;
        case 2:
          this.$emit('tab-click', index, y);
          break;
        default:
          break;
      }
    },
    tabClickChen(index) {
      console.log("触发：", index);

      console.log(this.router);
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.index {
  width: 100%;
  height: 100%;
  // background-color: aqua;
  position: absolute;
  top: 0;
  left: 0;

  overflow: hidden;

  .IndexBody {
    width: 100%;
    height: 100%;
    overflow-y: scroll;

    margin-top: 60px;

    // background-color: rgb(185, 54, 54);

    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
  }

}

.cardAboutFwxx {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  // background-color: #666666;

  position: relative;

  .handTitle {
    font-family: Source Han Sans;
    font-size: 24px;
    font-weight: normal;
    line-height: 158.43%;
    letter-spacing: 0.03em;

    font-variation-settings: "opsz" auto;
    font-feature-settings: "kern" on;
    color: #666666;

    // background-color: #333333;

    height: 200px;
    width: 1180px;

    letter-spacing: 0.1em;

    position: relative;

    margin-top: 120px;

    .ht2 {
      position: absolute;
      bottom: 5px;
      left: 0;

      width: 100%;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }


  }

  .bodyCard {
    width: 100%;
    height: 70%;
    background-color: transparent;

    position: absolute;
    bottom: 0;
    left: 0;


    display: flex;
    flex-direction: row;
    align-items: center;


    .leftXxfw {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: right;

      width: 55%;
      height: 100%;

      // background-color: #666666;
    }

    .rightXxfw {
      width: 55%;
      height: 100%;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: left;

      margin-left: 5%;

      .rightXxfwTitle {
        // background-color: #333333;
        height: 600px;
        width: 500px;

        display: flex;
        flex-direction: column;

        div {
          display: flex;
          flex-direction: column;
          margin-bottom: 100px;

          .titleR {
            font-family: Source Han Sans;
            font-size: 30px;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0em;

            font-variation-settings: "opsz" auto;
            font-feature-settings: "kern" on;
            color: #333333;

            margin-bottom: 20px;
          }

          .textr {
            font-family: Source Han Sans;
            font-size: 24px;
            font-weight: normal;
            line-height: 161.71%;
            letter-spacing: 0em;

            font-variation-settings: "opsz" auto;
            font-feature-settings: "kern" on;
            color: #666666;
          }
        }
      }

      // background-color: aqua;
    }
  }
}

.cardAboutJZTX {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  // background-color: #666666;

  .jztxCard {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .jztxImgCard {
      height: 450px;
      width: 400px;
      background-color: white;
      border-radius: 14px;
      margin-left: 80px;
      margin-right: 80px;
      margin-top: 150px;

      display: flex;
      flex-direction: column;


      .title {
        font-family: Source Han Sans;
        font-size: 30px;
        font-weight: 500;
        line-height: 158.43%;
        letter-spacing: 0.03em;

        font-variation-settings: "opsz" auto;
        font-feature-settings: "kern" on;
        color: #333333;

        margin-left: 20px;
        margin-top: 20px;
      }

      .t1 {
        font-family: Source Han Sans;
        font-size: 24px;
        font-weight: normal;
        line-height: 158.43%;
        letter-spacing: 0.03em;
        font-variation-settings: "opsz" auto;
        font-feature-settings: "kern" on;
        color: #666666;

        margin-left: 20px;
      }

    }


    // background-color: red;
  }
}

.cardAbout {
  width: 100%;
  height: 100%;
  // background-color: aqua;

  display: flex;
  flex-direction: row;

  .left {
    height: 100%;
    width: 45%;
    // background-color: bisque;

    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
  }

  .right {
    height: 100%;
    width: 55%;

    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;

    padding-left: 70px;

    position: relative;

    .text {
      width: 700px;
      height: 320px;

      font-family: Source Han Sans;
      font-size: 24px;
      font-weight: normal;
      line-height: 158.43%;
      letter-spacing: 0.03em;

      font-variation-settings: "opsz" auto;
      font-feature-settings: "kern" on;
      color: #666666;

      letter-spacing: 0.2em;

      position: relative;

      .p2 {
        position: absolute;
        bottom: 5px;
        left: 0;
        width: 100%;
      }
    }
  }
}
</style>
