<template>
  <div class="mode" :style="{ width: ImgWidth }">
    <img class="img" :src="url" width="100%">
    <div class="endText">
      <div class="endBody">
        <div class="left">
          <div class="lef">
            <div>首页</div>
            <div @click="MovenTsgn(0, 0.5)">特色功能</div>
          </div>
          <div class="lef">
            <div>APP下载</div>
            <div @click="MovenTsgn(1, 0.6)">快速安装</div>
          </div>
          <div class="lef">
            <div>服务热线</div>
            <div class="phone" @click="MovenTsgn(2, 0.15)">公司简介 </div>
            <div class="emal" @click="MovenTsgn(2, 0.35)">价值体系 </div>
            <div class="emal" @click="MovenTsgn(2, 0.63)">服务信息 </div>
          </div>
        </div>
        <div class="center">
        </div>
        <div class="right">
          <div class="one">
            <div class="rightHind">微信公众号</div>
            <div class="rightBox">
              <el-image style="width: 90px; height: 90px;margin-top: 7px;" :src="gzh" />
              <div style="font-size: 11px;color: #333333;">关注获取资讯</div>
            </div>
          </div>
          <div class="twoEnd">
            <div class="rightHind">抖音官方号 </div>
            <div class="rightBox">
              <el-image style="width: 90px; height: 90px;margin-top: 7px;" :src="dy" />
              <div style="font-size: 11px;color: #333333;">扫一扫加关注</div>
            </div>
          </div>

        </div>
      </div>
      <div class="endBodyend">
        <div>
        </div>
        <div>
          <!-- <span>沪公网安备 871785255656546号</span> -->
          <span style="margin-left: 20px;">沪ICP备2024089849号-3</span>
          <span style="margin-left: 20px;">信息安全等级保护（二级） </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['ImgWidth', "url"],
  components: {

  },
  name: 'EndView',
  data() {
    return {
      TabWidth: "1800px",
      gzh: "../web/BDs2vSLpNK.jpg",
      dy: "../web/dy.jpg",

    }
  },
  methods: {
    MovenTsgn(index, y) {
      console.log("MovenTsgn", index, y);
      this.$emit('move-click', index, y);
    },
    tabClickChen(index) {
      console.log("触发：", index);
    },
  }
}
</script>

<style scoped lang="scss">
.mode {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .text {
    position: absolute;
  }

  .endText {
    position: absolute;
    color: #01BB74;
    width: 100%;
    height: 100%;

    .endBody {
      width: 100%;
      height: 80%;

      display: flex;
      flex-direction: row;

      .left {
        width: 60%;
        height: 100%;
        color: white;

        margin-left: 150px;

        display: flex;
        flex-direction: row;
        justify-content: center;

        .lef {
          padding-top: 70px;
          display: flex;
          flex-direction: column;
          width: 250px;
          text-align: left;


          div {
            font-size: 15px;
            margin-top: 10px;
          }

          div:nth-child(1) {
            padding-top: 10px;
            font-size: 17px;
            font-weight: bold;
          }

          div:nth-child(1):hover {
            color: rgb(255, 255, 255);
          }

          div:hover {
            color: #d8d8d8;
          }

          .emal:hover {
            color: rgb(255, 255, 255);
          }

          .phone:hover {
            color: rgb(255, 255, 255);
          }

        }

      }

      .center {
        width: 2%;
        height: 70%;
        border-right: 1px solid #A8A5A5;
        margin-top: 55px;
      }

      .right {
        width: 38%;
        height: 40%;
        padding-top: 85px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        // background-color: #01BB74;


        .one {
          margin-right: 40px;
        }

        .one {
          .rightHind {
            font-size: 17px;
            font-weight: bold;
            width: 100px;
            color: white;
            margin-left: 3%;
          }

          .rightBox {
            width: 100px;
            height: 120px;
            background-color: #ffffff;
            border-radius: 8px;
            margin-top: 15px;

            display: flex;
            flex-direction: column;
            align-items: center;

            margin-left: 3%;
          }
        }


        .twoEnd {
          .rightHind {
            font-size: 17px;
            font-weight: bold;
            width: 100px;
            color: white;
            margin-left: 3%;
          }

          .rightBox {
            width: 100px;
            height: 120px;
            background-color: #ffffff;
            border-radius: 8px;
            margin-top: 15px;

            display: flex;
            flex-direction: column;
            align-items: center;

            margin-left: 3%;
          }
        }


      }

    }

    .endBodyend {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 20%;

      text-align: center;

      div {
        font-size: 12px;
        color: #A8A6A6;
        width: 70%;
        margin-bottom: 30px;
      }

      div:nth-child(1) {
        border-top: 1px solid #A8A5A5;
      }


    }
  }
}
</style>