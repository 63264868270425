<template>
  <div class="IndexBody" ref="scrollContainer">
    <ImgView :url="IndexUrl[0]" :ImgWidth="ImgWidth" loading="lazy" ImgHeight="850px">
      <!-- <template v-slot:body>  
              <h1>这是头部内容</h1>  
            </template> -->
    </ImgView>
    <ImgView :url="IndexUrl[1]" :ImgWidth="ImgWidth" ImgHeight="800px"></ImgView>
    <ImgView :url="IndexUrl[2]" :ImgWidth="ImgWidth" ImgHeight="900px"></ImgView>
    <ImgView :url="IndexUrl[3]" :ImgWidth="ImgWidth" ImgHeight="900px"></ImgView>
    <ImgView :url="IndexUrl[5]" :ImgWidth="ImgWidth" ImgHeight="900px"></ImgView>
    <!-- <ImgView :url="IndexUrl[6]" :ImgWidth="ImgWidth"></ImgView> -->
    <EndView :url="IndexUrl[6]" :ImgWidth="ImgWidth" @move-click="move"></EndView>
    <!-- EndView -->
  </div>
</template>

<script>
import ImgView from '../modularization/ImgView.vue'
import EndView from '../modularization/EndView.vue'
import { useRouter } from 'vue-router'

export default {
  props: ['ImgWidth'],
  components: {
    ImgView,
    EndView
  },
  name: 'indexPage',
  data() {
    return {
      TabWidth: "1800px",
      IndexUrl: [
        "../web/001.webp",
        "../web/002.png",
        "../web/003.png",
        "../web/004.png",
        "../web/006.png",
        "../web/005.webp",
        "../web/09.png",
      ],
      router: null,
      page: 0
    }
  },
  mounted() {
    const router = useRouter()
    this.router = router;
    console.log("home");

  },
  methods: {
    MOVEScroll(y) {
      var that = this;
      setTimeout(function () {
        that.$refs.scrollContainer.scrollTop = that.$refs.scrollContainer.scrollHeight * y;
      }, 100)
      console.log(y);

    },
    move(index, y) {
      switch (index) {
        case 0:
          this.$emit('tab-click', index, y);
          break;
        case 1:
          this.$emit('tab-click', index, y);
          break;
        case 2:
          this.$emit('tab-click', index, y);
          break;
        default:
          break;
      }
    }
  }
}
</script>

<style scoped lang="scss">
.IndexBody {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;

  // margin-top: 60px;

  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}
</style>